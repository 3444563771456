<template>
    <div class="mapsDataContainer">
        <NoMaps v-if="!loading && isEmpty" />
        <div v-else-if="!loading" class="wrapMaps">
            <TabsBar :image-height="imageHeight" />
            <div class="displayMap">
                <img v-if="selectedMap && selectedMap.imgBase64" :src="resizedImageSrc" class="mapStyle"
                    :alt="selectedMap.name || ''" ref="mapImg" @load="updateImageHeight">
            </div>
        </div>
        <div v-else class="wrapLoader">
            <span class="loader" />
            <span class="loaderTxt">
                Carregando...
            </span>
        </div>
    </div>
</template>

<script>

import NoMaps from './NoMaps.vue';
import TabsBar from './TabsBar.vue';
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';

export default {
    components: { NoMaps, TabsBar },
    name: 'MapsContainer',
    data() {
        return {
            maps: undefined,
            selectedMap: [],
            loading: true,
            imageHeight: 0,
            isImageLoaded: false
        }
    },
    methods: {
        updateImageHeight() {
            if (!this.$refs.mapImg) return;

            const updateHeight = () => {
                const imgElement = this.$refs.mapImg;
                if (imgElement && imgElement.clientHeight > 0) {
                    this.imageHeight = imgElement.clientHeight;
                }
            };

            updateHeight();

            this.$refs.mapImg.onload = updateHeight;
        },
        updateMapListContainer() {
            this.maps = MapProfilesControl.currentProfile?.maps
            this.selectedMap = MapProfilesControl.currentMap
        }
    },
    computed: {
        resizedImageSrc() {
            return this.selectedMap && this.selectedMap.imgBase64 ? this.selectedMap.imgBase64 : '';
        },
        isEmpty() {
            return !this.maps || this.maps.length === 0;
        }
    },
    mounted() {
        this.$nextTick(() => {
            const imgElement = this.$refs.mapImg;
            if (imgElement) {
                imgElement.onload = () => {
                    console.log(`Displayed image dimensions: ${imgElement.naturalWidth}x${imgElement.naturalHeight}`);
                    this.updateImageHeight();
                };
            }
            window.addEventListener('resize', this.updateImageHeight);
        });
    },
    created() {
        if((this.maps?.length === 0 || this.maps === undefined) && Array.isArray(MapProfilesControl.currentProfile?.maps)){
            this.maps = MapProfilesControl.currentProfile.maps
            this.selectedMap = MapProfilesControl.currentMap
        }
        
        MapProfilesControl.addListenerChangedProfileMap(this.updateMapListContainer);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateImageHeight);
        MapProfilesControl.removeListenerChangedProfileMap(this.updateMapListContainer);
    },
    watch: {
        selectedMap: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$nextTick(this.updateImageHeight);
                }
            }
        },
        maps: {
        immediate: true,
        handler(newVal) {
            // Only stop loading when we actually get data (even if empty array)
            if (Array.isArray(newVal)) {
                this.loading = false;
            }
        }
    }
    }
}
</script>

<style scoped>
.mapsDataContainer {
    width: 98%;
    height: auto;
    height: 84%;
    display: flex;
    margin: auto;
}

.wrapMaps {
    width: 100%;
}

.displayMap {
    background-color: #36393F;
    height: calc(100% - 30px);
    padding: 5px;
    border-radius: 0 0 6px 6px;
}

.mapStyle {
    width: 100%;
    height: 100%;
}

.loader {
    width: 58px;
    height: 58px;
    border: 5px solid #1E2227;
    border-bottom-color: #4375EF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.wrapLoader {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.loaderTxt {
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(255, 255, 255, .7);
    font-size: 18px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(max-width: 825px) {
    .mapsDataContainer {
        margin-top: 20px;
    }
}

@media(max-width: 525px) {
    .mapsDataContainer {
        margin-top: 0px;
    }
}
</style>