<template>
  <div class="wrapLocationBar">
    <p class="barTitle">Inserir Central</p>

    <div class="groupItens">
      <div class="select-wrapper">
        <label for="names" class="input-label">Mapa de Destino:</label>
        <div class="custom-select" @click.stop="toggleDropdown">
          <span class="inputSpan">{{ selectedName || 'Selecione um mapa' }}</span>
          <img src="@/assets/icons/maps/inputArrow.svg" class="inputArrowImg" />
        </div>

        <ul v-if="dropdownOpen" class="dropdown-list" @click.stop>
          <li v-for="(map, index) in mapsList" :key="map.name" @click="selectName(map)"
            :style="{ backgroundColor: index % 2 === 0 ? '#36393F' : '#27272C' }" class="dropDownItem">
            {{ map.name }}
          </li>
        </ul>
      </div>

      <div class="input-container">
        <input type="text" v-model="textValue" id="textInput" placeholder="Pesquisar dispositivo" @input="handleSearch"
          class="locationBarInput" />
        <img src="@/assets/icons/maps/search.svg" alt="Add" class="icon" />
      </div>

      <div class="wrapAvailableDevices">
        <div v-for="(device, index) in shownDevices" :key="index" class="device-item"
          :style="{ backgroundColor: index % 2 === 0 ? '#36393F' : '#27272C' }">
          <div class="wrapPanelInfo">
            <label style="color: rgba(255,255,255, .5)">{{ device.client.name }} - {{ device.description }}</label>
            <label>{{ device.unity.name }}</label>
            <label>{{ `${device.unity.city}-${device.unity.state}, ${device.unity.country.name}` }}</label>
            <label>{{ device.hardwareId }}</label>
          </div>
          <input type="checkbox" :id="'device-' + index" :value="device" v-model="selectedDevices" />
        </div>
        <div v-if="shownDevices.length <= 0" class="wrapNoPanel">
          <img src="@/assets/icons/maps/locationNotFound.svg" class="noPanelImg">
          <span class="noPanelTxt">Nenhum painel encontrado</span>
        </div>
      </div>

      <button class="saveBtn" @click="handleSave">
        Salvar
      </button>
    </div>
  </div>
</template>

<script>
import DeviceControl from '@/sys/Control/Device/DeviceControl';
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';

export default {
  data() {
    return {
      selectedName: MapProfilesControl.currentMap.name,
      mapsList: MapProfilesControl.currentProfile.maps,
      dropdownOpen: false,
      textValue: '',
      selectedDevices: [],
      availableDevices: DeviceControl.listOfDevices,
      shownDevices: []
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectName(map) {
      this.selectedName = map.name;
      MapProfilesControl.currentMap = map
      MapProfilesControl.callbackChangedProfileMap()
      this.dropdownOpen = false;
    },
    handleSave() {
      this.$emit('save', this.selectedDevices);
    },
    handleClickOutside(event) {
      const dropdown = this.$el.querySelector('.select-wrapper');
      if (this.dropdownOpen && !dropdown.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    handleSearch() {
      if (!this.textValue.trim()) {
        this.shownDevices = this.availableDevices;
        return;
      }

      const searchTerm = this.textValue.toLowerCase().trim();

      this.shownDevices = this.availableDevices.filter(device => {
        const searchableFields = [
          device.client.name,
          device.unity.name,
          device.unity.city,
          device.unity.state,
          device.unity.country.name,
          device.hardwareId,
          device.description
        ];

        return searchableFields.some(field =>
          field.toLowerCase().includes(searchTerm)
        );
      });
    }
  },
  mounted() {
    this.shownDevices = this.availableDevices;
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>


<style>
.wrapLocationBar {
  position: absolute;
  left: 5px;
  top: 35px;
  width: 250px;
  background-color: #292D32;
  z-index: 1000;
}

.select-wrapper {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}

.inputArrowImg {
  position: absolute;
  top: 20px;
  right: 0;
  height: 30px;
  cursor: pointer;
}

.input-container {
  position: relative;
  height: 30px;
  margin-bottom: 15px;
  border-radius: 0 6px 6px 0;
}

.locationBarInput {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  box-sizing: border-box;
  height: 30px;
  border-radius: 6px;
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  color: #78768d;
  font-size: 12px;
  background-color: #1E2227;
  border: none;
  outline: none;
}

.locationBarInput:focus {
  border: none;
  outline: none;
}

.icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
  pointer-events: auto;
  background-color: #4375EF;
  height: 30px;
  width: 30px;
  padding: 7px;
  border-radius: 0 6px 6px 0;
}

.wrapAvailableDevices {
  min-height: 242px;
  max-height: 242px;
  margin-bottom: 45px;
  background-color: #1E2227;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.groupItens {
  width: 95%;
  margin: auto;
}

.barTitle {
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.input-label {
  font-weight: regular;
  color: #78768d;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
}

.inputSpan {
  display: block;
  height: 30px;
  border-radius: 6px;
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  color: #78768d;
  font-size: 12px;
  background-color: #1E2227;
}

.dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 120px;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  border: 2px #4374EF solid;
  border-radius: 0px 0px 6px 6px;
  border-top: none;
}

.dropDownItem {
  height: 30px;
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  color: #78768d;
  font-size: 12px;
  cursor: pointer;
}

.saveBtn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #4375EF;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 12px 5px 12px;
  cursor: pointer
}

.device-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, .2);
  font-size: 12px;
  padding: 10px 5px 10px 5px;
}

label {
  margin-left: 5px;
}

.wrapPanelInfo {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 3px
}

.wrapNoPanel {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  padding: 15px 0;
  gap: 15px;
  margin: auto 0;
}

.noPanelImg {
  width: 25%;
}

.noPanelTxt {
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

@media(max-height: 400px) {
  .wrapAvailableDevices {
    min-height: 80px;
    max-height: 80px;
    margin-bottom: 33px;
  }
}
</style>
