<template>
    <div class="noDataDiv">
        <div v-if="noMapsLoading" class="wrapLoader">
            <span class="loader" />
            <span class="loaderTxt">
                Carregando...
            </span>
        </div>
        <div class="wrapNoMapsInfo" v-else>
            <img src="@/assets/icons/emptyFolder.svg" class="noDataImg" />
            <p class="noDataP">{{ !showBtn ? "Ainda não existem mapas cadastrados" : "Nenhum mapa encontrado" }}</p>

            <label class="noDataBtn" v-if="!showBtn">
                Adicionar mapa
                <input type="file" class="hidden-input" @change="handleFileChange" accept="image/*" />
            </label>
        </div>
    </div>
</template>

<script>
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';

export default {
    name: 'NoMaps',
    data() {
        return {
            imageData: null,
            showBtn: MapProfilesControl.auxNotFound,
            noMapsLoading: false
        }
    },
    methods: {
        async handleFileChange(event) {
            const file = event.target.files[0];
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

            if (!file) return;

            if (!allowedTypes.includes(file.type)) {
                console.log('Por favor, selecione apenas arquivos de imagem nos formatos: JPG, JPEG, PNG, GIF ou WEBP');
                event.target.value = '';
                return;
            }

            // Process filename to extract "WhatsApp Image" if it matches the pattern
            let fileName = file.name;
            // This regex matches "WhatsApp Image" followed by a date and time pattern
            const whatsappPattern = /^(WhatsApp Image)\s+\d{4}-\d{2}-\d{2}\s+at\s+\d{1,2}\.\d{2}\.\d{2}/;
            if (whatsappPattern.test(fileName)) {
                fileName = "WhatsApp Image";
            }
            
            const lastDotIndex = fileName.lastIndexOf('.');
            fileName = fileName.substring(0, lastDotIndex); 

            try {
                const base64 = await this.convertToBase64(file);

                this.imageData = base64;
                

                const data = {
                    profileId: MapProfilesControl.currentProfile._id,
                    map: {
                        name: fileName,
                        imgBase64: this.imageData
                    }
                }

                this.noMapsLoading = true
                await MapProfilesControl.addMapToProfile(data)
                this.noMapsLoading = false

                event.target.value = '';
            } catch (error) {
                console.error('Error processing image:', error);
            }
        },
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
            });
        }
    }
}
</script>

<style scoped>
.noDataDiv {
    margin: auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 35%;
    min-width: 250px;
}

.noDataImg {
    margin: 0 auto;
    width: 50%;
    min-width: 150px;
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 28%;
}

.noDataP {
    font-size: 22px;
    color: #D4D5D6;
    width: fit-content;
    align-self: center;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.noDataBtn {
    background-color: #4375EF;
    border: none;
    width: fit-content;
    padding: 15px;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: bold;
    align-self: center;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
}

.hidden-input {
    display: none;
}

.loader {
    width: 58px;
    height: 58px;
    border: 5px solid #1E2227;
    border-bottom-color: #4375EF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.wrapLoader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.loaderTxt {
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(255, 255, 255, .7);
    font-size: 18px;
}

.wrapNoMapsInfo{
    display: flex;
    flex-direction: column;
}
 
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>