<template>
  <div :style="{
    top: `calc(${pin.y} - 51px)`,
    left: `calc(${pin.x} - 42.5px)`,
  }" @mousedown="handleMouseDown" @dblclick="handleDoubleClick" @touchstart="handleTouchStart"
    @touchend="handleTouchEnd" :data-index="index" class="wrap-pin">
    <div class="pin-container">
      <div :class="[
        'pinIcon',
        {
          clickedIn: isSelected,
          offlinePin: !pin.device.isOnline,
          onFire: isOnFire,
          'disabled': isLoading
        }
      ]" />
      <div v-if="isLoading" class="wrapLoaderPin">
        <div class="loaderPin"></div>
      </div>
      <div v-else>
        <img class="stateIcon" :src="pinImgSrc" />
      </div>
      <div class="pin-txt-container" ref="txtContainer">
        <div class="pin-txt-wrapper" ref="txtWrapper" :class="{ 'animate': shouldAnimate }">
          <span class="pin-txt">{{ pin.device.unity.name ? pin.device.unity.name : 'NOP' }}</span>
          <span v-if="shouldAnimate" class="pin-txt">{{ pin.device.unity.name ? pin.device.unity.name : 'NOP' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CENTRAL_TYPE, STATUS_COUNT_INDEX } from '@/sys/Model/Device/Device';
export default {
  name: 'PinLocation',
  props: {
    pin: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shouldAnimate: false,
      pinImgSrc: null,
      lastTap: 0,
      touchHoldTimeout: null,
      tapTimeout: 300,
      touchStartTime: 0,
      touchStartX: 0,
      touchStartY: 0,
      isDragging: false
    }
  },
  mounted() {
    this.$nextTick(this.checkTextOverflow);
    this.updatePinImage();
  },
  methods: {
    handleMouseDown(event) {
      if (this.isLoading) {
        event.preventDefault();
        return;
      }
      event.preventDefault();
      this.$emit('start-drag', event, this.index);
    },
    handleDoubleClick(event) {
      event.stopPropagation();
      this.$emit('pin-selected', this.index);
    },
    handleTouchStart(event) {
      if (this.isLoading) {
        event.preventDefault();
        return;
      }

      const currentTime = new Date().getTime();
      const tapLength = currentTime - this.lastTap;

      if (tapLength < this.tapTimeout && tapLength > 0) {
        // Double tap detected
        event.preventDefault();
        clearTimeout(this.touchHoldTimeout);
        this.$emit('pin-selected', this.index);
        this.isDragging = false;
        return;
      }

      this.lastTap = currentTime;

      // Set up touch hold for drag
      this.touchHoldTimeout = setTimeout(() => {
        this.isDragging = true;
        this.$emit('start-drag', event, this.index);
      }, 200); // Adjust this delay if needed
    },
    handleTouchEnd(event) {
      clearTimeout(this.touchHoldTimeout);

      if (!this.isDragging) {
        // Single tap detected (not part of a drag)
        this.$emit('pin-selected', this.index);
      }

      this.isDragging = false;
    },
    checkTextOverflow() {
      const wrapper = this.$refs.txtWrapper;
      if (wrapper) {
        const firstSpan = wrapper.firstElementChild;
        this.shouldAnimate = firstSpan.textContent.length > 6;
        if (this.shouldAnimate) {
          wrapper.style.setProperty('--text-width', `${firstSpan.offsetWidth}px`);
        }
      }
    },
    isESP() {
      return this.pin.device.type.id === CENTRAL_TYPE.GATEWAY;
    },
    updatePinImage() {
      if (!this.pin.device.isOnline) {
        this.pinImgSrc = this.isESP()
          ? require("@/assets/icons/noConnection.svg")
          : require("@/assets/icons/alert/Offline.svg");
      } else {
        if (this.isESP()) {
          if (this.pin.device.systemInfo.fireAlert) {
            this.pinImgSrc = require("@/assets/icons/redFire.svg");
          } else if (this.pin.device.systemInfo.failureAlert) {
            this.pinImgSrc = require("@/assets/icons/warning.svg");
          } else {
            this.pinImgSrc = require("@/assets/icons/checked.svg");
          }
        } else {
          if (this.pin.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE] > 0) {
            this.pinImgSrc = require("@/assets/icons/alert/fire.svg");
          } else {
            if (this.pin.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE] > 0) {
              this.pinImgSrc = require("@/assets/icons/alert/failure.svg");
            } else {
              if (this.pin.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY] > 0) {
                this.pinImgSrc = require("@/assets/icons/alert/lowBattery2.svg");
              } else {
                if (this.pin.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION] > 0) {
                  this.pinImgSrc = require("@/assets/icons/alert/supervision.svg");
                } else {
                  if (this.pin.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE] > 0) {
                    this.pinImgSrc = require("@/assets/icons/alert/disabled2.svg");
                  } else {
                    this.pinImgSrc = require("@/assets/icons/alert/idle.svg");
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  watch: {
    pin: {
      handler() {
        this.updatePinImage();
        this.$nextTick(this.checkTextOverflow);
      },
      deep: true
    }
  },
  computed: {
    isOnFire() {
      return this.pin.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE] > 0;
    }
  }
}
</script>

<style scoped>
.wrap-pin {
  position: absolute;
  cursor: grab;
  touch-action: none;
}

.wrap-pin:active {
  cursor: grabbing;
}

.pin-container {
  position: relative;
  width: 85px;
  height: 102px;
}

.pinIcon {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 24px;
  height: 24px;
  background-color: #292D32;
  border-radius: 50% 50% 0 50%;
  transform: rotate(45deg);
  scale: 3.5;
}

.clickedIn {
  -webkit-box-shadow: 0px 0px 0px 1.5px rgba(67, 117, 239, 1);
  -moz-box-shadow: 0px 0px 0px 1.5px rgba(67, 117, 239, 1);
  box-shadow: 0px 0px 0px 1.5px rgba(67, 117, 239, 1);
}

.stateIcon {
  position: absolute;
  top: 8px;
  left: 15px;
  transform: translate(-50%, -50%);
  scale: .4;
}

.pin-txt-container {
  position: absolute;
  top: 70px;
  left: 43px;
  transform: translate(-50%, -50%);
  width: 45px;
  overflow: hidden;
  text-align: center;
}

.pin-txt-wrapper {
  display: inline-flex;
  white-space: nowrap;
}

.pin-txt {
  display: inline-block;
  font-size: 10px;
  color: #FFFFFF;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-right: 10px;
  padding-left: 8px;
}

.pin-txt-wrapper.animate {
  animation: carousel 10s linear infinite;
}

.offlinePin {
  background-color: #666666;
}


.onFire {
  animation: pulse 1.5s infinite;
}

.disabled {
  cursor: not-allowed !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.wrapLoaderPin {
  height: 40px;
  width: 40px;
  position: relative;
  left: 22px;
  top: 15px;
}

.loaderPin {
  top: 200px;
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid #4374EF;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg)
  }

  49.99% {
    transform: scaleY(1) rotate(135deg)
  }

  50% {
    transform: scaleY(-1) rotate(0deg)
  }

  100% {
    transform: scaleY(-1) rotate(-135deg)
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 42, 42, 1);
  }

  70% {
    box-shadow: 0 0 0 2px rgba(255, 42, 42, 1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 42, 42, 0);
  }
}

@keyframes carousel {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-1 * var(--text-width)));
  }
}
</style>