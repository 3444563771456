<template>
    <div class="wrapDevicePopUp">
        <span class="deviceInfoTitle">
            {{ device.type.name }}
        </span>

        <div class="wrapDeviceInfo">
            <span class="deviceInfoName">
                {{ device.client.name }}
            </span>

            <span class="deviceInfoUpperTxt">
                {{ device.unity.name }}
            </span>

            <span class="deviceInfoUpperTxt">
                {{ device.description }}
            </span>

            <span class="deviceInfoUpperTxt">
                {{ `${device.unity.city}-${device.unity.state}, ${device.unity.country.name}` }}
            </span>
        </div>

        <div class="wrapDeviceMiddleInfo">
            <img src="@/assets/icons/alert/fire.svg" :src="cardImgSrc" class="wrapDeviceMiddleImg">
            <span class="deviceMiddleInfoTxt">{{ imgLabel }}</span>
        </div>

        <div class="wrapDeviceInfoBtns">
            <button class="deviceInfoBtn" style="background-color: #FF2A2A" @click="handleRemoveDevice">
                Remover
            </button>

            <div class="deviceInfoBtn" v-if="device.isOnline" style="background-color: #4375EF">
                <span>
                    Exibir
                </span>
                <router-link class="deviceRouterLinkMap"
                    :to="{ name: 'AcessType', params: { device: device } }"></router-link>
            </div>

        </div>
    </div>
</template>

<script>
import MapProfilesContro from '@/sys/Control/MapProfiles/MapProfilesControl';
import { CENTRAL_TYPE, STATUS_COUNT_INDEX } from '@/sys/Model/Device/Device';

export default {
    name: 'DevicePopUp',
    data() {
        return {
            cardImgSrc: null,
            imgLabel: ''
        }
    },
    props: {
        device: Object,
        selectedMap: {
            type: Object,
            required: true
        },
    },
    methods: {
        handleRemoveClick() {
            console.log("Remove button clicked!");
        },
        handleRemoveDevice() {
            MapProfilesContro.removePanelFromMap({ selectedMapId: this.selectedMap._id, panel: this.device })
            this.$emit('remove-device');
        },
        defineImgSrc() {
            if (!this.device.isOnline) {
                this.cardImgSrc = this.device.type.id === CENTRAL_TYPE.GATEWAY
                    ? require("@/assets/icons/noConnection.svg")
                    : require("@/assets/icons/alert/Offline.svg");

                this.imgLabel = this.device.type.id === CENTRAL_TYPE.GATEWAY
                    ? 'Sem conexão'
                    : 'Offline';
            } else {
                if (this.device.type.id === CENTRAL_TYPE.GATEWAY) {
                    if (this.device.systemInfo.fireAlert) {
                        this.cardImgSrc = require("@/assets/icons/redFire.svg");
                        this.imgLabel = 'Alerta de fogo'
                        const span = document.querySelector('.deviceMiddleInfoTxt')
                        span.style.color = '#FF2A2A'
                    } else if (this.device.systemInfo.failureAlert) {
                        this.cardImgSrc = require("@/assets/icons/warning.svg");
                        this.imgLabel = 'Alerta de falha'
                        const span = document.querySelector('.deviceMiddleInfoTxt')
                        span.style.color = '#FFC22D'
                    } else {
                        this.cardImgSrc = require("@/assets/icons/checked.svg");
                        this.imgLabel = ''
                    }
                } else {
                    if (this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE] > 0) {
                        this.cardImgSrc = require("@/assets/icons/alert/fire.svg");
                        this.imgLabel = 'Alerta de fogo'
                        const span = document.querySelector('.deviceMiddleInfoTxt')
                        span.style.color = '#FF2A2A'
                    } else {
                        if (this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE] > 0) {
                            this.cardImgSrc = require("@/assets/icons/alert/failure.svg");
                            this.imgLabel = 'Alerta de falha'
                            const span = document.querySelector('.deviceMiddleInfoTxt')
                            span.style.color = '#FFC22D'
                        } else {
                            if (this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY] > 0) {
                                this.cardImgSrc = require("@/assets/icons/alert/lowBattery2.svg");
                                this.imgLabel = 'Bateria baixa'
                                const span = document.querySelector('.deviceMiddleInfoTxt')
                                span.style.color = '#4375EF'
                            } else {
                                if (this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION] > 0) {
                                    this.cardImgSrc = require("@/assets/icons/alert/supervision.svg");
                                    this.imgLabel = 'Alerta de supervisão'
                                    const span = document.querySelector('.deviceMiddleInfoTxt')
                                    span.style.color = '#FB5E13'
                                } else {
                                    if (this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE] > 0) {
                                        this.cardImgSrc = require("@/assets/icons/alert/disabled2.svg");
                                        this.imgLabel = 'Desativado'
                                    } else {
                                        this.cardImgSrc = require("@/assets/icons/alert/idle.svg");
                                        this.imgLabel = ''
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.defineImgSrc();
    }
}
</script>

<style>
.wrapDevicePopUp {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50px;
    margin-right: 20px;
    padding: 15px;
    gap: 15px;
    width: 300px;
    border-radius: 6px;
    background-color: #292D32;
    z-index: 10;
}

.wrapDeviceInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.wrapDeviceInfoBtns {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.deviceInfoTitle {
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    background-color: #1A1A22;
}

.deviceInfoName {
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 14px;
    font-weight: normal;
}

.deviceInfoUpperTxt {
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    width: fit-content;
    color: #5C5D5E;
    font-size: 11px;
    font-weight: normal;
}

.deviceInfoBtn {
    color: #FFFFFF;
    position: relative;
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    padding: 5px 15px;
    border-radius: 3px;
    cursor: pointer;
}

.wrapDeviceMiddleInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.deviceMiddleInfoTxt {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    width: fit-content;
    color: #FFFFFF;
}

.wrapDeviceMiddleImg {
    height: 50px;
    width: fit-content;
}

.deviceRouterLinkMap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
</style>