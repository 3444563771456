<template>
    <div class="ResponsiveContentBoard">
        <div class="titleArea">
            <div class="titleContainer">
                <img class="titleBackgound" src="@/assets/icons/titleBackgound.svg" />
                <div class="titleText textNormal">
                    Lista de Centrais
                </div>
            </div>
            <div class="wrapInputs">
                <SwitchBtn v-model="isChecked" @input="handleSwitch" class="switchStyle" />
                <div class="inputContainer">
                    <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="deviceSearch">
                    <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
                </div>
            </div>
        </div>

        <MapsContainer v-if="isChecked" />
        <div v-if="!isChecked && isLoading" class="wrapLoader">
            <span class="loader" />
            <span class="loaderTxt">
                Carregando...
            </span>
        </div>
        <div v-if="!isChecked && !isLoading" class="parentContainer">
            <div v-for="device in deviceListFilter" :key="device.id" class="Device"
                :class="{ DeviceWithAlert: device.fireAlert }">
                <DeviceCardView :device="device" :id="'device-' + device.hardwareId" />
                <router-link v-if="device.isOnline" class="deviceRouterLink"
                    :to="{ name: 'AcessType', params: { device: device } }"></router-link>
            </div>
        </div>
        <div class="FakeDivList"></div>
        <div class="FakeDevice" v-if="!isChecked" />
        <div class="FakeDevice" v-if="!isChecked" />
        <div class="FakeDevice" v-if="!isChecked" />
        <div class="FakeDevice" v-if="!isChecked" />
        <PopUp msg="Carregando lista de dispositivos..." :show="load" type="Loader" />
        <PopUp msg="Verrifique sua conexão..." :show="fault" type="Fault" :onOk="ok()" />
    </div>
</template>

<script>
import DeviceCardView from './DeviceCardView.vue'
import PopUp from '@/views/PopUp/PopUp.vue'
import DeviceControl from '@/sys/Control/Device/DeviceControl'
import BackEnd from '@/sys/BackEnd/BanckEnd'
import { STATUS_COUNT_INDEX, CENTRAL_TYPE } from '@/sys/Model/Device/Device'
import MapsContainer from '../Maps/MapsContainer.vue'
import SwitchBtn from '../Maps/SwitchBtn.vue'
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl'
import { ViewMode } from '@/sys/Model/Permissions/ViewMode'

export default {
    components: { DeviceCardView, PopUp, MapsContainer, SwitchBtn },
    data: function () {
        return {
            load: true,
            fault: false,
            isLoading: true,
            listOfDevices: DeviceControl.getListOfDevices((result) => {
                this.$nextTick(() => {
                    this.load = false;
                    MapProfilesControl.callbackChangedProfileMap()
                    if (!result) {
                        this.fault = true;
                    }
                });
            }, BackEnd.getCurrentUserId()),
            deviceSearch: '',
            isChecked: false
        }
    },
    computed: {
        deviceListFilter() {
            let deviceFireAlert = [], deviceFailureAlert = [], deviceBatteryAlert = [], deviceSupervisionlert = [], deviceDisabledAlert = []
            let sortedList, offline = [], otherDevices = [], deviceFHTWithoutStatus = []

            for (const device of this.listOfDevices) {

                if (device.isOnline) {
                    if (device.type.id <= CENTRAL_TYPE.MB15_8 || device.type.id == CENTRAL_TYPE.FHT7_2 || device.type.id == CENTRAL_TYPE.FHT7_8) {
                        if (device.status) {
                            if (device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE] > 0) {
                                deviceFireAlert.push(device)
                                continue
                            }
                            if (device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE] > 0) {
                                deviceFailureAlert.push(device)
                                continue
                            }
                            if (device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY] > 0) {
                                deviceBatteryAlert.push(device)
                                continue
                            }
                            if (device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION] > 0) {
                                deviceSupervisionlert.push(device)
                                continue
                            }
                            if (device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE] > 0) {
                                deviceDisabledAlert.push(device)
                                continue
                            }
                        }
                        deviceFHTWithoutStatus.push(device)
                        continue
                    }
                    otherDevices.push(device)
                } else {
                    offline.push(device)
                }
            }

            sortedList = deviceFireAlert.concat(deviceFailureAlert)
            sortedList = sortedList.concat(deviceBatteryAlert)
            sortedList = sortedList.concat(deviceSupervisionlert)
            sortedList = sortedList.concat(deviceDisabledAlert)
            sortedList = sortedList.concat(deviceFHTWithoutStatus)
            sortedList = sortedList.concat(otherDevices)
            sortedList = sortedList.concat(offline)
            return sortedList.filter(device => {
                return device.client.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                    || device.type.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                    || device.unity.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                    || device.unity.city.toLowerCase().includes(this.deviceSearch.toLowerCase())
                    || device.unity.state.toLowerCase().includes(this.deviceSearch.toLowerCase())
                    || device.hardwareId.toLowerCase().includes(this.deviceSearch.toLowerCase())
                    || device.description.toLowerCase().includes(this.deviceSearch.toLowerCase())
            })
        }
    },
    methods: {
        ok() {
            this.fault = false
        },
        handleSwitch(value) {
            this.isChecked = value;
            /* Setting view preferences */
            value ? MapProfilesControl.setDefaultUserViewMode({ viewMode: ViewMode.MAPS }) : MapProfilesControl.setDefaultUserViewMode({ viewMode: ViewMode.CARD_LIST })
            if (!value) {
                this.isLoading = true;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 100);
                });
            }
        },
        updateRealPanelsList(newList) {
            MapProfilesControl.refreshPanelsList(newList)
            MapProfilesControl.panelsList = newList
        },
    },
    watch: {
        deviceSearch(newVal) {

            if (this.isChecked) {
                if (newVal === '') {
                    MapProfilesControl.currentProfile.maps = MapProfilesControl.auxMapList;
                    MapProfilesControl.currentMap = MapProfilesControl.currentProfile.maps[0];
                    MapProfilesControl.callbackChangedProfileMap();
                } else {
                    const filteredMaps = MapProfilesControl.auxMapList.filter(map => map.name.toLowerCase().includes(newVal.toLowerCase()))
                    MapProfilesControl.auxNotFound = filteredMaps.length === 0;
                    MapProfilesControl.currentProfile.maps = filteredMaps;
                    MapProfilesControl.currentMap = filteredMaps[0];
                    MapProfilesControl.callbackChangedProfileMap();
                }
            }
        },
        deviceListFilter(newVal) {
            if (newVal.length > 0 && MapProfilesControl.panelsList.length === 0) {
                this.updateRealPanelsList(newVal);
                MapProfilesControl.callbackChangedProfileMap();
            }
        },

    },
    async created() {
        if (!MapProfilesControl.currentProfile) {
            await MapProfilesControl.fillListOfProfiles()
        }
        await BackEnd.user.refreshCustomData();
        const viewMode = BackEnd.getCurrentUser().customData.viewMode;
        const viewModeValue = viewMode?.$numberInt ? parseInt(viewMode.$numberInt) : false;

        /* Check if view preferences are set or not */
        if (viewModeValue) {
            switch (viewModeValue) {
                case ViewMode.CARD_LIST:
                    /* Setting as card list view */
                    this.isChecked = false;
                    break
                case ViewMode.MAPS:
                    /* Setting as map view */
                    this.isChecked = true;
                    break
            }
        } else {
            /* Setting as card list view */
            this.isChecked = false;
            MapProfilesControl.setDefaultUserViewMode({ viewMode: ViewMode.CARD_LIST })
        }
        this.isLoading = false;
    }
}
</script>

<style scoped>
.deviceRouterLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.titleContainer {
    position: relative;
    margin-bottom: 5px;
    width: 40%;
    float: left;
}

.titleArea {
    margin: 12px 0 0 10px;
    width: 100%;
}

.titleText {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.titleBackgound {
    width: 700px;
}

.inputContainer {
    float: right;
    width: 19.5%;
    margin-left: auto;
    margin-top: 15px;
}

.switchStyle {
    margin-left: 31%;
    margin-top: 20px;
}

.Device {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: fit-content;
    text-decoration: none
}

.DeviceWithAlert {
    z-index: 12
}

.FakeDevice {
    display: flex;
    flex-direction: row;
    margin: 5px;
    flex-basis: 430px;
    /*Sempre acrescentar 10px da margem do device card*/
    flex-grow: 1;
}

.ResponsiveContentBoard {
    align-content: flex-start;
    padding: 0 5px;
}

.shadowSmooth {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

.parentContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 10px;
    width: 100%;
}

.loader {
    width: 58px;
    height: 58px;
    border: 5px solid #1E2227;
    border-bottom-color: #4375EF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.wrapLoader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.loaderTxt {
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(255, 255, 255, .7);
    font-size: 18px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(max-width: 1890px) {
    .switchStyle {
        margin-left: 30%;
    }
}

@media(max-width: 1830px) {
    .switchStyle {
        margin-left: 28%;
    }
}

@media(max-width: 1750px) {
    .switchStyle {
        margin-left: 26%;
    }
}

@media(max-width: 1650px) {
    .switchStyle {
        margin-left: 24%;
    }
}

@media(min-width: 1670px) {
    .parentContainer {
        grid-template-columns: repeat(4, minmax(280px, 1fr));
    }
}

@media(max-width: 1570px) {
    .switchStyle {
        margin-left: 23%;
    }
}

@media(max-width: 1530px) {
    .switchStyle {
        margin-left: 21%;
    }
}

@media(max-width: 1470px) {
    .switchStyle {
        margin-left: 19%;
    }
}

@media(max-width: 1450px) {
    .titleText {
        width: 90%;
    }

    .titleBackgound {
        width: 600px;
    }

    .FakeDevice {
        flex-basis: 330px;
        /*Sempre acrescentar 10px da margem do device card*/
    }
}

@media(max-width: 1360px) {
    .switchStyle {
        margin-left: 17%;
    }
}

@media(max-width: 1290px) {
    .switchStyle {
        margin-left: 15%;
    }
}

@media(max-width: 1275px) {
    .inputContainer {
        margin-top: 0px;
        width: 100%;
    }

    .switchStyle {
        margin-top: 0px;
        margin-left: 45%;
    }

    .titleArea {
        display: flex;
        justify-content: space-between;
    }

    .titleBackgound {
        height: 100%;
    }

}

@media(max-width: 1150px) {
    .inputContainer {
        margin-top: 20px;
        width: 35%;
    }
}

@media(max-width: 1000px) {
    .titleBackgound {
        width: 100%;
    }

    .titleContainer {
        width: 100%;
    }

    .inputContainer {
        margin-top: 0;
    }

    .FakeDivList {
        width: 100%;
        height: 20%;
    }
}

@media(max-width: 825px) {
    .inputContainer {
        width: 100%;
    }

    .switchStyle {
        margin-left: 18%;
    }
}

@media(max-width: 800px) {
    .titleArea {
        flex-direction: column;
    }

    .switchStyle {
        margin-left: 0px;
    }

    .wrapInputs {
        margin-top: 10px;
    }

    .inputContainer {
        width: fit-content;
    }

    .inputSearch {
        margin-top: 0px;
        width: 350px;
    }

    .searchIcon {
        top: 5px;
    }
}

@media(max-width: 600px) {
    .titleBackgound {
        width: 100%;
    }

    .titleText {
        width: 90%;
    }

    .titleContainer {
        width: 100%;
    }

    .FakeDevice {
        width: 100%;
    }
}

@media(max-width: 540px) {
    .wrapInputs {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .inputContainer {
        width: 100%;
    }

    .inputSearch {
        width: 100%;
    }
}

@media(max-width: 350px) {
    .inputContainer {
        width: 100%;
    }
}
</style>