<template>
    <div class="checkbox-wrapper-35">
        <input :checked="value" name="switch" id="switch" type="checkbox" class="switch" @change="toggleChecked" />
        <label for="switch">
            <span class="switch-x-text">Modo</span>
            <span class="switch-x-toggletext">
                <span class="switch-x-unchecked">
                    <span class="switch-x-hiddenlabel">Unchecked: </span>Cartões
                </span>
                <span class="switch-x-checked">
                    <span class="switch-x-hiddenlabel">Checked: </span>Mapa
                </span>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'SwitchBtn',
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        toggleChecked() {
            this.$emit('input', !this.value); // Emit the input event with the toggled value
        }
    }
};
</script>


<style scoped>
.checkbox-wrapper-35 {
    width: 120px;
    flex-shrink: 0;
    /* Prevents shrinking */
    display: inline-block;
    /* Ensures the wrapper behaves as a block element, controlling width */
    margin-left: 190px;
    margin-top: 10px;
}

.checkbox-wrapper-35 .switch {
    display: none;
}

.checkbox-wrapper-35 .switch+label {
    align-items: center;
    color: #78768d;
    cursor: pointer;
    display: flex;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 15px;
    position: relative;
    user-select: none;
    width: 180px;
}

.checkbox-wrapper-35 .switch+label::before,
.checkbox-wrapper-35 .switch+label::after {
    content: '';
    display: block;
}

.checkbox-wrapper-35 .switch+label::before {
    background-color: #05012c;
    border-radius: 500px;
    height: 25px;
    /* Altura da parada da bolinha */
    margin-right: 8px;
    transition: background-color 0.125s ease-out;
    width: 50px;
    /* Largura da parada da bolinha */
}

.checkbox-wrapper-35 .switch+label::after {
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 3px 1px 0 rgba(37, 34, 71, 0.05), 0 2px 2px 0 rgba(37, 34, 71, 0.1), 0 3px 3px 0 rgba(37, 34, 71, 0.05);
    height: 23.3px;
    /* Altura da da bolinha */
    left: 1px;
    position: absolute;
    top: 1px;
    transition: transform 0.125s ease-out;
    width: 23.3px;
    /* Largura da bolinha */
}

.checkbox-wrapper-35 .switch+label .switch-x-text {
    margin-right: 0.3em;
}

.checkbox-wrapper-35 .switch+label .switch-x-toggletext {
    font-weight: bold;
    height: 15px;
    overflow: hidden;
    position: relative;
    width: 60px;
    /* Largura do texto animado */
}

.checkbox-wrapper-35 .switch+label .switch-x-unchecked,
.checkbox-wrapper-35 .switch+label .switch-x-checked {
    position: absolute;
    top: 0;
    transition: opacity 0.125s ease-out, transform 0.125s ease-out;
}

.checkbox-wrapper-35 .switch+label .switch-x-unchecked {
    opacity: 1;
}

.checkbox-wrapper-35 .switch+label .switch-x-checked {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
}

.checkbox-wrapper-35 .switch+label .switch-x-hiddenlabel {
    position: absolute;
    visibility: hidden;
}

.checkbox-wrapper-35 .switch:checked+label::before {
    background-color: #4375EF;
}

.checkbox-wrapper-35 .switch:checked+label::after {
    transform: translate3d(24px, 0, 0);
}

.checkbox-wrapper-35 .switch:checked+label .switch-x-unchecked {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
}

.checkbox-wrapper-35 .switch:checked+label .switch-x-checked {
    opacity: 1;
    transform: none;
}

@media(max-width: 1275px) {
    .checkbox-wrapper-35 {
        margin-left: 43%;
    }
}

@media(max-width: 1150px) {
    .checkbox-wrapper-35 {
        margin-left: 43%;
    }
}

@media(max-width: 1050px) {
    .checkbox-wrapper-35 {
        margin-left: 42%;
    }
}

@media(max-width: 1000px) {
    .checkbox-wrapper-35 {
        margin-left: 0;
    }
}

@media(max-width: 5250px) {
    .checkbox-wrapper-35 {
        margin-bottom: 20px;
    }
}

@media(max-width: 350px) {
    .checkbox-wrapper-35 {
        margin-left: 0;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}
</style>