var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap-pin",style:({
  top: ("calc(" + (_vm.pin.y) + " - 51px)"),
  left: ("calc(" + (_vm.pin.x) + " - 42.5px)"),
}),attrs:{"data-index":_vm.index},on:{"mousedown":_vm.handleMouseDown,"dblclick":_vm.handleDoubleClick,"touchstart":_vm.handleTouchStart,"touchend":_vm.handleTouchEnd}},[_c('div',{staticClass:"pin-container"},[_c('div',{class:[
      'pinIcon',
      {
        clickedIn: _vm.isSelected,
        offlinePin: !_vm.pin.device.isOnline,
        onFire: _vm.isOnFire,
        'disabled': _vm.isLoading
      }
    ]}),(_vm.isLoading)?_c('div',{staticClass:"wrapLoaderPin"},[_c('div',{staticClass:"loaderPin"})]):_c('div',[_c('img',{staticClass:"stateIcon",attrs:{"src":_vm.pinImgSrc}})]),_c('div',{ref:"txtContainer",staticClass:"pin-txt-container"},[_c('div',{ref:"txtWrapper",staticClass:"pin-txt-wrapper",class:{ 'animate': _vm.shouldAnimate }},[_c('span',{staticClass:"pin-txt"},[_vm._v(_vm._s(_vm.pin.device.unity.name ? _vm.pin.device.unity.name : 'NOP'))]),(_vm.shouldAnimate)?_c('span',{staticClass:"pin-txt"},[_vm._v(_vm._s(_vm.pin.device.unity.name ? _vm.pin.device.unity.name : 'NOP'))]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }