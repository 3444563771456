<template>
  <div class="wrapTabs">
    <div class="wrapRealTabs">
      <div class="wrapHamburguer" @click.stop="toggleMenu">
        <img src="@/assets/icons/maps/hamburguerMenu.svg" class="hamburguerImg">
      </div>

      <div class="wrapPlus" @click="handleAddMap">
        <img src="@/assets/icons/outros/plusDevice.svg" class="plusImg" />
      </div>

      <div class="mapNames">
        <div v-for="map in maps" :key="map.name" @click="selectMap(map)">
          <div class="tabName" :class="{ 'selected': selectedMap && selectedMap.name === map.name }">
            <div class="tab-txt-wrapper" ref="tabWrapper" :class="{ 'animate': shouldAnimate(map.name) }">
              <span class="tab-txt">{{ map.name || '' }}</span>
              <span v-if="shouldAnimate(map.name)" class="tab-txt">{{ map.name || '' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wraphamburguericons" :class="{ visible: isHamburgerMenuVisible }">
      <div v-for="(icon, index) in icons" :key="icon.src" class="icon-wrapper" :class="{ show: isHamburgerMenuVisible }"
        :style="{ transitionDelay: isHamburgerMenuVisible ? `${index * 0.1}s` : '0s' }">
        <div class="tooltip-container">
          <img :src="icon.src" class="hamburguerMenuIcon" @click.stop="handleIconClick(icon.src)" />
          <span class="tooltip">{{ icon.tooltip }}</span>
        </div>
      </div>
    </div>
    <AddLocationBar v-if="isAddLocationBarVisible" ref="addLocationBar" @save="handleSaveClick" />
    <MapModals ref="modal" />
    <DevicePopUp v-if="selectedPinIndex !== null"
      :device="selectedPinIndex !== null ? pins[selectedPinIndex].device : null" :selectedMap="selectedMap"
      @close="closeDevicePopUp" @remove-device="removeDevice" />
    <div ref="wrapPins" :style="wrapPinsStyle">
      <PinLocation v-for="(pin, index) in pins" :key="index" :pin="pin" :index="index"
        :isSelected="selectedPinIndex === index" :isLoading="isPinLoading(index)" @start-drag="startDrag"
        @pin-selected="handlePinSelection" />
    </div>
  </div>
</template>

<script>
import AddLocationBar from './AddLocationBar.vue'
import MapModals from './MapModals.vue'
import PinLocation from './PinLocation.vue'
import DevicePopUp from './DevicePopUp.vue'
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';
import DeviceControl from '@/sys/Control/Device/DeviceControl';
import BackEnd from '@/sys/BackEnd/BanckEnd';


export default {
  name: 'TabsBar',
  props: {
    imageHeight: {
      type: Number,
      default: 0
    },
  },
  components: {
    AddLocationBar,
    MapModals,
    PinLocation,
    DevicePopUp
  },
  data() {
    return {
      maps: [],
      isAddLocationBarVisible: false,
      isHamburgerMenuVisible: false,
      icons: [
        { src: require('@/assets/icons/maps/addLocation.svg'), tooltip: 'Adicionar Painel' },
        { src: require('@/assets/icons/maps/mapConfig.svg'), tooltip: 'Configurar mapa' },
        { src: require('@/assets/icons/maps/removeMap.svg'), tooltip: 'Excluir mapa' },
        { src: require('@/assets/icons/maps/removeAllLocations.svg'), tooltip: 'Remover todos os painéis' },
        { src: require('@/assets/icons/maps/createProfile.svg'), tooltip: 'Criar Perfil' }
      ],
      selectedPinIndex: null,
      dragInfo: {
        dragging: false,
        index: null,
        startX: 0,
        startY: 0,
        initialX: 0,
        initialY: 0
      },
      selectedMap: {},
      panelsList: DeviceControl.listOfDevices,
      pins: [],
      isUpdating: false,
      clickTimer: null,
      clickDelay: 300,
      preventDrag: false,
      isDevicePopUpOpen: false,
      hasMoved: false,
      dragInfo: {
        dragging: false,
        index: null,
        startX: 0,
        startY: 0,
        initialX: 0,
        initialY: 0,
        currentX: 0,
        currentY: 0
      },
      isDeletingPin: false,
      isAddingPins: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isHamburgerMenuVisible = !this.isHamburgerMenuVisible;
    },
    handleIconClick(iconSrc) {
      switch (iconSrc) {
        case require('@/assets/icons/maps/addLocation.svg'):
          this.isAddLocationBarVisible = !this.isAddLocationBarVisible;
          this.isHamburgerMenuVisible = false;
          break;
        case require('@/assets/icons/maps/removeMap.svg'):
          this.$refs.modal.showModal(1, this.selectedMap?.name, this.removeSelectedMap);
          this.isHamburgerMenuVisible = false;
          break;
        case require('@/assets/icons/maps/mapConfig.svg'):
          this.$refs.modal.showModal(2, 'This is the Map Configuration modal.');
          this.isHamburgerMenuVisible = false;
          break;
        case require('@/assets/icons/maps/removeAllLocations.svg'):
          this.$refs.modal.showModal(3, this.selectedMap?.name, this.resetPins);
          this.isHamburgerMenuVisible = false;
          break;
        case require('@/assets/icons/maps/createProfile.svg'):
          if(this.checkHowManyProfiles()){
            this.$refs.modal.showModal(8, `O limite de perfis deste usuário foi atingido`);
          }else{
            this.$refs.modal.showModal(5, this.selectedMap?.name);
          }
          this.isHamburgerMenuVisible = false;
          break;
      }
    },
    handleAddMap() {
      if (MapProfilesControl.currentProfile.maps.length >= 12){
        this.$refs.modal.showModal(8, `O limite de mapas deste perfil foi atingido`);
      } else {
        this.$refs.modal.showModal(4, this.selectedMap?.name);
      }
    },
    async handleSaveClick(data) {
      this.isAddingPins = true;
      const wrapRect = this.$refs.wrapPins.getBoundingClientRect();

      // Pin offset values (from your CSS)
      const pinOffsetX = 42.5;
      const pinOffsetY = 51;

      // Calculate safe boundaries in percentages
      const safeAreaX = (pinOffsetX / wrapRect.width) * 100;
      const safeAreaY = (pinOffsetY / wrapRect.height) * 100;

      let newPanelsAsPins = data.map((deviceData) => {
        // Generate random positions within safe boundaries
        const xPercentage = safeAreaX + (Math.random() * (100 - 2 * safeAreaX));
        const yPercentage = safeAreaY + (Math.random() * (100 - 2 * safeAreaY));

        return {
          x: `${xPercentage}%`,
          y: `${yPercentage}%`,
          device: deviceData
        };
      });

      const filteredPins = newPanelsAsPins.filter(newPanel =>
        !this.pins.some(pinedPanel => pinedPanel.device.hardwareId === newPanel.device.hardwareId)
      );

      this.pins = [
        ...this.pins,
        ...filteredPins
      ];

      const panels = filteredPins.map((deviceData) => ({
        _id: deviceData.device._id,
        hardwareId: deviceData.device.hardwareId,
        x: deviceData.x,
        y: deviceData.y
      }));

      this.isAddLocationBarVisible = false;

      try {
        await MapProfilesControl.addPanelsToMap({
          panels,
          profileId: MapProfilesControl.getCurrentProfile()._id,
          selectedMapId: this.selectedMap._id
        });

        // Refresh the map data
        await this.fillData();
      } catch (error) {
        console.error('Error adding panels:', error);
      } finally {
        this.isAddingPins = false;
      }
    },
    validatePinPosition(x, y) {
      const wrapRect = this.$refs.wrapPins.getBoundingClientRect();
      const pinOffsetX = 42.5;
      const pinOffsetY = 51;

      // Calculate safe boundaries in percentages
      const safeAreaX = (pinOffsetX / wrapRect.width) * 100;
      const safeAreaY = (pinOffsetY / wrapRect.height) * 100;

      // Ensure x is within safe boundaries
      x = Math.max(safeAreaX, Math.min(100 - safeAreaX, x));
      // Ensure y is within safe boundaries
      y = Math.max(safeAreaY, Math.min(100 - safeAreaY, y));

      return { x, y };
    },
    startDrag(event, index) {
      if (MapProfilesControl.isMovingPanel || this.preventDrag || this.isDevicePopUpOpen) {
        return;
      }

      event.preventDefault();
      const pin = this.pins[index];
      const isTouch = event instanceof TouchEvent;
      const pos = isTouch ? event.touches[0] : event;

      this.hasMoved = false; // Reset movement flag
      this.dragInfo = {
        dragging: true,
        type: isTouch ? 'touch' : 'mouse',
        index,
        startX: pos.clientX,
        startY: pos.clientY,
        initialX: pin.x,
        initialY: pin.y,
        currentX: pos.clientX, // Initialize current position
        currentY: pos.clientY
      };

      if (isTouch) {
        document.addEventListener('touchmove', this.dragPin, { passive: false });
        document.addEventListener('touchend', this.stopDrag);
      } else {
        document.addEventListener('mousemove', this.dragPin);
        document.addEventListener('mouseup', this.stopDrag);
      }
    },
    dragPin(event) {
      if (!this.dragInfo.dragging || this.preventDrag) return;
      event.preventDefault();

      const pos = this.dragInfo.type === 'touch' ? event.touches[0] : event;

      // Update current position
      this.dragInfo.currentX = pos.clientX;
      this.dragInfo.currentY = pos.clientY;

      // Check if there's actual movement (more than 5px to account for tiny accidental movements)
      const deltaX = Math.abs(this.dragInfo.currentX - this.dragInfo.startX);
      const deltaY = Math.abs(this.dragInfo.currentY - this.dragInfo.startY);
      if (deltaX > 5 || deltaY > 5) {
        this.hasMoved = true;
      }

      // Only update pin position if there's actual movement
      if (this.hasMoved) {
        const wrapRect = this.$refs.wrapPins.getBoundingClientRect();
        const deltaX = pos.clientX - this.dragInfo.startX;
        const deltaY = pos.clientY - this.dragInfo.startY;

        const initialXPixels = (parseFloat(this.dragInfo.initialX) / 100) * wrapRect.width;
        const initialYPixels = (parseFloat(this.dragInfo.initialY) / 100) * wrapRect.height;

        let newXPixels = initialXPixels + deltaX;
        let newYPixels = initialYPixels + deltaY;

        let newXPercent = (newXPixels / wrapRect.width) * 100;
        let newYPercent = (newYPixels / wrapRect.height) * 100;

        const validPosition = this.validatePinPosition(newXPercent, newYPercent);

        this.$set(this.pins, this.dragInfo.index, {
          ...this.pins[this.dragInfo.index],
          x: `${validPosition.x}%`,
          y: `${validPosition.y}%`,
        });
      }
    },
    async stopDrag() {
      // First, remove event listeners to stop tracking movement
      if (this.dragInfo.type === 'touch') {
        document.removeEventListener('touchmove', this.dragPin);
        document.removeEventListener('touchend', this.stopDrag);
      } else {
        document.removeEventListener('mousemove', this.dragPin);
        document.removeEventListener('mouseup', this.stopDrag);
      }

      if (!this.dragInfo.dragging || this.preventDrag) return;

      // Only update if there was actual movement
      if (this.hasMoved) {
        this.isUpdating = true;
        const pin = this.pins[this.dragInfo.index];

        try {
          await MapProfilesControl.movePanel({
            selectedMapId: this.selectedMap._id,
            panel: {
              device: pin.device,
              x: pin.x,
              y: pin.y,
            },
          });
        } catch (error) {
          console.error('Error moving panel:', error);
        } finally {
          this.isUpdating = false;
        }
      } else {
        // If there was no movement, treat it as a click
        this.$emit('pin-selected', this.dragInfo.index);
      }

      // Reset all drag-related states immediately
      this.dragInfo = {
        dragging: false,
        index: null,
        startX: 0,
        startY: 0,
        initialX: 0,
        initialY: 0,
        currentX: 0,
        currentY: 0,
        type: null
      };
      this.hasMoved = false;
    },
    isPinHidden(index) {
      const pinElement = this.$refs.wrapPins.children[index];
      const wrapElement = this.$refs.wrapPins;

      if (!pinElement || !wrapElement) return false;

      const pinRect = pinElement.getBoundingClientRect();
      const wrapRect = wrapElement.getBoundingClientRect();

      // Add a small buffer (1px) to prevent edge cases
      const buffer = 1;

      const isOutsideHorizontally =
        pinRect.left + buffer < wrapRect.left ||
        pinRect.right - buffer > wrapRect.right;

      const isOutsideVertically =
        pinRect.top + buffer < wrapRect.top ||
        pinRect.bottom - buffer > wrapRect.bottom;

      return isOutsideHorizontally || isOutsideVertically;
    },
    handlePinSelection(index) {
      if (this.selectedPinIndex === index) {
        this.selectedPinIndex = null;
        this.isDevicePopUpOpen = false;
      } else {
        this.selectedPinIndex = index;
        this.isDevicePopUpOpen = true;
      }
    },
    closeDevicePopUp() {
      this.selectedPinIndex = null;
      this.isDevicePopUpOpen = false;
    },
    handleOutsideClick(event) {
      // Check if click is on a button or link inside the popup
      const isButton = event.target.tagName === 'BUTTON' ||
        event.target.tagName === 'A' ||
        event.target.closest('button') ||
        event.target.closest('a');

      if (isButton) {
        return; // Do not process outside click for buttons/links
      }

      // DevicePopUp logic
      if (this.selectedPinIndex !== null) {
        const popupElement = this.$el.querySelector('.wrapDeviceInfo');
        const pinElement = this.$el.querySelector('.wrap-pin.clickedIn');

        const isClickInsidePopup = popupElement && (popupElement === event.target || popupElement.contains(event.target));
        const isClickInsidePin = pinElement && (pinElement === event.target || pinElement.contains(event.target));

        if (!isClickInsidePopup && !isClickInsidePin) {
          this.closeDevicePopUp();
          return;
        }
      }

      // AddLocationBar logic
      if (this.isAddLocationBarVisible) {
        const addLocationBarElement = this.$refs.addLocationBar.$el;
        if (!addLocationBarElement.contains(event.target)) {
          this.isAddLocationBarVisible = false;
          return; // Return early to prevent further processing
        }
      }

      // Hamburger menu logic
      if (this.isHamburgerMenuVisible) {
        const hamburgerMenuElement = this.$el.querySelector('.wraphamburguericons');
        const hamburgerButtonElement = this.$el.querySelector('.wrapHamburguer');
        if (!hamburgerMenuElement.contains(event.target) && !hamburgerButtonElement.contains(event.target)) {
          this.isHamburgerMenuVisible = false;
          return; // Return early to prevent further processing
        }
      }
    },
    selectMap(map) {
      if (MapProfilesControl.isMovingPanel) {
        return
      }
      MapProfilesControl.currentMap = map
      MapProfilesControl.callbackChangedProfileMap()
      this.fillData()
    },
    resetPins() {
      MapProfilesControl.removeAllPanelsFromMap();
      this.pins = [];
    },
    removeSelectedMap() {
      if (this.selectedMap) {
        // Emit an event to the parent component to remove the map
        this.$emit('remove-map', this.selectedMap);
      }
    },
    async removeDevice() {
      if (this.selectedPinIndex !== null) {
        const removedPin = this.pins[this.selectedPinIndex];

        // Close popup immediately
        this.selectedPinIndex = null;
        this.isDevicePopUpOpen = false;

        // Enable loading state for all pins
        this.isDeletingPin = true;

        try {
          // Call the remove panel API
          await MapProfilesControl.removePanelFromMap({
            selectedMapId: this.selectedMap._id,
            panel: removedPin.device
          });

          // Remove the pin from the array
          this.pins = this.pins.filter((_, index) => index !== removedPin.index);

          // Update the remaining pins' indices
          this.pins = this.pins.map((pin, index) => ({
            ...pin,
            index: index
          }));

          // Refresh the map data
          await this.fillData();

        } catch (error) {
          console.error('Error removing pin:', error);
        } finally {
          this.isDeletingPin = false; // Disable loading state for all pins
        }
      }
    },
    updateMapList() {
      this.maps = MapProfilesControl.currentProfile?.maps
      this.selectedMap = MapProfilesControl.currentMap
      this.fillPinsValues();
    },
    fillData() {
      this.fillPinsValues();

      // Reset drag states
      this.isUpdating = false;
      this.dragInfo.dragging = false;
      this.dragInfo.index = null;
    },
    shouldAnimate(text) {
      return text.length > 15;
    },
    handleOutsideTouch(event) {
      // Check if touch is on a button or link inside the popup
      const isButton = event.target.tagName === 'BUTTON' ||
        event.target.tagName === 'A' ||
        event.target.closest('button') ||
        event.target.closest('a');

      if (isButton) {
        return; // Do not process outside touch for buttons/links
      }

      // DevicePopUp logic
      if (this.selectedPinIndex !== null) {
        const popupElement = this.$el.querySelector('.wrapDeviceInfo');
        const pinElement = this.$el.querySelector('.wrap-pin.clickedIn');

        const isTouchInsidePopup = popupElement && (popupElement === event.target || popupElement.contains(event.target));
        const isTouchInsidePin = pinElement && (pinElement === event.target || pinElement.contains(event.target));

        if (!isTouchInsidePopup && !isTouchInsidePin) {
          this.closeDevicePopUp();
          return;
        }
      }

      // AddLocationBar logic
      if (this.isAddLocationBarVisible) {
        const addLocationBarElement = this.$refs.addLocationBar.$el;
        if (!addLocationBarElement.contains(event.target)) {
          this.isAddLocationBarVisible = false;
          return; // Return early to prevent further processing
        }
      }

      // Hamburger menu logic
      if (this.isHamburgerMenuVisible) {
        const hamburgerMenuElement = this.$el.querySelector('.wraphamburguericons');
        const hamburgerButtonElement = this.$el.querySelector('.wrapHamburguer');
        if (!hamburgerMenuElement.contains(event.target) && !hamburgerButtonElement.contains(event.target)) {
          this.isHamburgerMenuVisible = false;
          return; // Return early to prevent further processing
        }
      }
    },
    checkHowManyProfiles(){
      let foundthisMany = 0;

      MapProfilesControl.listOfProfiles.forEach(profile => {
        profile.users.forEach(user => {
          `${user._id}` === `${BackEnd.getCurrentUser().customData._id}` && foundthisMany++
        })
      })

      return foundthisMany >= 3;
    },
    fillPinsValues(){
      let mapPanelsArr;

      if (!this.selectedMap?.panels) {
        this.pins = [];
        return;
      } else {
        mapPanelsArr = this.selectedMap.panels;
      }
      this.panelsList = DeviceControl.listOfDevices;

      // Catch related panels
      const defaultPanelsArr = this.panelsList.filter((panel) =>
        mapPanelsArr.some((mapPanel) => `${panel.hardwareId}` === `${mapPanel.hardwareId}`)
      );
      const resultArr = [];

      // build Pin Array structure 
      mapPanelsArr.forEach((mapPanel) => {
        const matchingPanel = defaultPanelsArr.find((panel) => panel.hardwareId === mapPanel.hardwareId);
        if (matchingPanel) {
          resultArr.push({
            x: mapPanel.x,
            y: mapPanel.y,
            device: matchingPanel
          });
        }
      });    
      // Ensure all event listeners are properly set up for the new pins array
      this.pins = resultArr;
    }
  },
  mounted() {
    window.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('touchstart', this.handleOutsideTouch);
    this.selectedMap = MapProfilesControl.currentMap;
    this.maps = MapProfilesControl.currentProfile.maps;
    const mapNames = document.querySelector('.mapNames');
    mapNames.addEventListener('wheel', (e) => {
      e.preventDefault();
      mapNames.scrollLeft += e.deltaY;
    });
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('touchstart', this.handleOutsideTouch); // Add this line
    MapProfilesControl.removeListenerChangedProfileMap(this.updateMapList);
    MapProfilesControl.removeListenerChangedRealPanelsList(this.fillData);
    if (this.clickTimer) {
      clearTimeout(this.clickTimer);
    }
  },
  created() {
    MapProfilesControl.addListenerChangedProfileMap(this.updateMapList);
    MapProfilesControl.addListenerChangedRealPanelsList(this.fillData);
    this.updateMapList();
    this.fillData()  
  },
  computed: {
    wrapPinsStyle() {
      return {
        height: `${this.imageHeight}px`,
        position: 'relative',
        width: 'calc(100% - 10px)',
        top: '5px',
        left: '5px'
      }
    },
    isPinLoading() {
      return (index) => {
        return this.isDeletingPin || this.isAddingPins || (this.isUpdating && index === this.dragInfo.index);
      };
    }
  },
}
</script>

<style>
.wrapTabs {
  width: 100%;
  height: 30px;
  position: relative;
}

.wrapHamburguer {
  background-color: #1A1A22;
  width: fit-content;
  padding: 5px;
  position: absolute;
  right: 0;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  z-index: 2;
}

.hamburguerImg {
  width: 20px;
  margin-top: 2px;
}

.wraphamburguericons {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 200px;
  position: absolute;
  right: 0;
  top: 30px;
  margin-right: 20px;
  overflow: hidden;
  z-index: 1;
  gap: 15px;
  padding-top: 35px;
  pointer-events: none;
  align-content: baseline;
}

.wraphamburguericons.visible {
  height: auto;
  pointer-events: auto;
}

.hamburguerMenuIcon {
  width: 50px;
  transform: translateY(-20px);
  cursor: pointer;
  transition: opacity 0.5s ease, transform 0.5s ease;
  margin-left: 150px;
}

.hamburguerMenuIcon.show {
  opacity: 1;
  transform: translateY(0);
}

.hamburguerMenuIcon:nth-child(1) {
  transition-delay: 0.1s;
}

.hamburguerMenuIcon:nth-child(2) {
  transition-delay: 0.2s;
}

.hamburguerMenuIcon:nth-child(3) {
  transition-delay: 0.3s;
}

.hamburguerMenuIcon:nth-child(4) {
  transition-delay: 0.4s;
}

.pin {
  scale: .25;
  position: absolute;
  cursor: grab;
}

.pin.dragging {
  cursor: grabbing;
}

.wrapPlus {
  background-color: #1A1A22;
  width: 30px;
  padding: 7px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  z-index: 2;
  cursor: pointer;
  margin-right: 5px;
}

.plusImg {
  width: 15px;
}

.tabName {
  width: fit-content;
  min-width: 120px;
  max-width: 120px;
  margin-left: 5px;
  background-color: #1A1A22;
  border-radius: 6px 6px 0 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #FFFFFF;
  font-size: 11px;
  line-height: 22px;
  font-weight: normal;
  padding: 5px 0px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
}

.mapNames {
  display: flex;
  width: 94%;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  padding: 0px;
  padding-right: 27px;
}

.mapNames::-webkit-scrollbar {
  display: none;
}

.wrapRealTabs {
  display: flex;
  height: 100%;
}

.selected {
  font-weight: bold;
  background-color: #36393F
}

.icon-wrapper {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.icon-wrapper.show {
  opacity: 1;
  transform: translateY(0);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 55%;
  left: 80px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: .8rem;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tab-txt-wrapper {
  display: inline-flex;
  white-space: nowrap;
}

.tab-txt {
  display: inline-block;
  padding-right: 10px;
  padding-left: 8px;
}

.tab-txt-wrapper.animate {
  animation: carousel 10s linear infinite;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
}

.pinLoader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  margin: auto;
}

.pinLoader::after,
.pinLoader::before {
  content: '';
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #4374EF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}

.pinLoader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}


@keyframes carousel {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

@media(max-width: 1350px) {
  .mapNames {
    width: 93%;
  }
}

@media(max-width: 1200px) {
  .mapNames {
    width: 92%;
  }
}

@media(max-width: 1050px) {
  .mapNames {
    width: 91%;
  }
}

@media(max-width: 980px) {
  .mapNames {
    width: 90.5%;
  }
}

@media(max-width: 900px) {
  .mapNames {
    width: 89%;
  }
}

@media(max-width: 800px) {
  .mapNames {
    width: 88%;
  }
}

@media(max-width: 770px) {
  .mapNames {
    width: 86%;
  }
}

@media(max-width: 690px) {
  .mapNames {
    width: 85%;
  }
}

@media(max-width: 650px) {
  .mapNames {
    width: 83%;
  }

  .pinLoader {
    margin-top: 70%;
    margin-left: 30%;
  }
}

@media(max-height: 400px) {
  .wraphamburguericons {
    gap: 5px;
  }

  .hamburguerMenuIcon {
    width: 40px;
  }

  .pinLoader {
    margin-top: 10%;
  }
}
</style>